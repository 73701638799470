// logger.ts

type LogLevel = 'info' | 'warn' | 'error';

const log = (level: LogLevel, message: string, ...args: any[]) => {
  const timestamp = new Date().toISOString();
  const formattedMessage = `[${timestamp}] [${level.toUpperCase()}] ${message}`;

  switch (level) {
    case 'info':
      console.log(formattedMessage, ...args);
      break;
    case 'warn':
      console.warn(formattedMessage, ...args);
      break;
    case 'error':
      console.error(formattedMessage, ...args);
      break;
  }

  // In a production environment, you might want to send logs to a server or logging service
  if (process.env.NODE_ENV === 'production') {
    // TODO: Implement production logging (e.g., send to a logging service)
    // Example: sendToLoggingService(level, formattedMessage, ...args);
  }
};

export const logger = {
  info: (message: string, ...args: any[]) => log('info', message, ...args),
  warn: (message: string, ...args: any[]) => log('warn', message, ...args),
  error: (message: string, ...args: any[]) => log('error', message, ...args),
};