import { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { WallStyle, Wall } from '../types';

const defaultWallStyles: WallStyle = {
  title_color: '#000000',
  title_font_size: '24px',
  message_color: '#000000',
  message_font_size: '16px',
  background_color: '#ffffff',
  image_aspect_ratio: '1:1',
  cols_sm: 2,
  cols_md: 3,
  cols_lg: 4,
  cols_xl: 5,
  cols_2xl: 6,
  cols_3xl: 7,
  cols_4xl: 8,
  cols_5xl: 9,
  wall_name_color: '#000000',
  post_background_color: '#ffffff',
  page_background_color: '#f0f0f0',
  header_background_color: '#ffffff',
  title_font: 'Arial',
  message_font: 'Arial',
};

export const useWallData = (name: string | undefined, refetchTrigger: number = 0) => {
  const [wall, setWall] = useState<Wall | null>(null);
  const [wallStyles, setWallStyles] = useState<WallStyle | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWallData = async () => {
      if (!name) {
        setIsLoading(false);
        return;
      }

      try {
        console.log('Fetching wall data for:', name);
        const { data: wallData, error: wallError } = await supabase
          .from('walls')
          .select(`
            *,
            organizations(name),
            enable_sharing,
            share_platforms,
            share_message_template,
            share_elements
          `)
          .eq('url', name)
          .single();

        if (wallError) {
          console.error('Error fetching wall data:', wallError);
          throw wallError;
        }

        if (!wallData) {
          console.error('Wall not found for:', name);
          setError('Wall not found');
          setIsLoading(false);
          return;
        }

        console.log('Fetched wall data:', wallData);
        setWall({
          ...wallData,
          organization_name: wallData.organizations ? wallData.organizations.name : null,
          enable_sharing: wallData.enable_sharing || false,
          share_platforms: wallData.share_platforms || { facebook: true, twitter: true, linkedin: true },
          share_message_template: wallData.share_message_template || 'Check out this post from {wall_name}!',
          share_elements: wallData.share_elements || { title: true, message: true, image: true }
        });

        if (wallData.id) {
          console.log('Fetching wall styles for wall ID:', wallData.id);
          const { data: styleData, error: styleError } = await supabase
            .from('wall_styles')
            .select('*')
            .eq('wall_id', wallData.id)
            .single();

          if (styleError) {
            console.error('Error fetching wall styles:', styleError);
            console.warn('Using default styles due to fetch error');
            setWallStyles(defaultWallStyles);
          } else if (styleData) {
            console.log('Fetched wall styles:', styleData);
            setWallStyles(styleData);
          } else {
            console.warn('No styles found for wall. Using default styles.');
            setWallStyles(defaultWallStyles);
          }
        }
      } catch (error) {
        console.error('Error in fetchWallData:', error);
        setError('Error loading wall data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWallData();
  }, [name, refetchTrigger]);

  return { wall, wallStyles, isLoading, error };
};