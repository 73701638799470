import React from 'react';
import { PopupPost, WallStyle, Wall } from '../types';

interface PostPopupProps {
  popupPost: PopupPost;
  wallStyles: WallStyle | null;
  wall: Wall;
  closePopup: () => void;
}

const PostPopup: React.FC<PostPopupProps> = ({ popupPost, wallStyles, wall, closePopup }) => {
  const getAspectRatio = (resolution: string) => {
    switch (resolution) {
      case '576x1024':
        return '9/16';
      case '1024x1024':
        return '1/1';
      case '1024x576':
        return '16/9';
      default:
        return wallStyles?.image_aspect_ratio?.replace(':', '/') || '1/1';
    }
  };

  const getShareUrl = () => {
    // Replace this with the actual URL of your public wall post
    return `https://yourdomain.com/wall/${wall.url}/post/${popupPost.id}`;
  };

  const getShareMessage = () => {
    let message = wall.share_message_template || 'Check out this post from {wall_name}!';
    message = message.replace('{wall_name}', wall.name);
    return message;
  };

  const handleShare = (platform: string) => {
    const shareUrl = getShareUrl();
    const shareMessage = getShareMessage();
    let url = '';

    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareMessage)}`;
        break;
    }

    window.open(url, '_blank');
  };

  const buttonStyle = {
    backgroundColor: '#60A5FA', // bg-blue-400
    color: '#FFFFFF',
    padding: '0.25rem 0.75rem',
    borderRadius: '0.25rem',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    transition: 'background-color 0.2s',
  };

  const buttonHoverStyle = {
    ...buttonStyle,
    backgroundColor: '#3B82F6', // bg-blue-500
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closePopup}>
      <div className="bg-white rounded-lg overflow-hidden max-w-[95vw] max-h-[95vh] flex" onClick={(e) => e.stopPropagation()}>
        <div className="flex-1 h-full flex items-center justify-center bg-gray-100">
          <img 
            src={popupPost.original_image_url} 
            alt={popupPost.title || 'Full size image'} 
            className="max-w-full max-h-full object-contain"
            style={{ aspectRatio: getAspectRatio(popupPost.resolution) }}
          />
        </div>
        <div className="w-1/3 p-6 flex flex-col">
          <div className="flex-grow overflow-y-auto">
            {popupPost.title && wall.share_elements?.title && (
              <h2 className="text-2xl font-bold mb-4" style={{ 
                color: wallStyles?.title_color || '#000000', 
                fontSize: wallStyles?.title_font_size || '24px',
                fontFamily: wallStyles?.title_font || 'Arial'
              }}>{popupPost.title}</h2>
            )}
            {popupPost.message && wall.share_elements?.message && (
              <p style={{ 
                color: wallStyles?.message_color || '#000000', 
                fontSize: wallStyles?.message_font_size || '16px',
                fontFamily: wallStyles?.message_font || 'Arial'
              }}>{popupPost.message}</p>
            )}
          </div>
          {wall.enable_sharing && (
            <div className="mt-4 flex justify-center space-x-2">
              {wall.share_platforms?.facebook && (
                <button
                  style={buttonStyle}
                  onMouseOver={(e) => Object.assign(e.currentTarget.style, buttonHoverStyle)}
                  onMouseOut={(e) => Object.assign(e.currentTarget.style, buttonStyle)}
                  onClick={() => handleShare('facebook')}
                >
                  Share on Facebook
                </button>
              )}
              {wall.share_platforms?.twitter && (
                <button
                  style={buttonStyle}
                  onMouseOver={(e) => Object.assign(e.currentTarget.style, buttonHoverStyle)}
                  onMouseOut={(e) => Object.assign(e.currentTarget.style, buttonStyle)}
                  onClick={() => handleShare('twitter')}
                >
                  Share on Twitter
                </button>
              )}
              {wall.share_platforms?.linkedin && (
                <button
                  style={buttonStyle}
                  onMouseOver={(e) => Object.assign(e.currentTarget.style, buttonHoverStyle)}
                  onMouseOut={(e) => Object.assign(e.currentTarget.style, buttonStyle)}
                  onClick={() => handleShare('linkedin')}
                >
                  Share on LinkedIn
                </button>
              )}
            </div>
          )}
          <button 
            className="mt-4 px-4 py-2 rounded shadow-md self-end"
            style={{
              backgroundColor: '#3B82F6', // bg-blue-500
              color: '#FFFFFF',
              transition: 'background-color 0.2s',
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#2563EB'} // bg-blue-600
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#3B82F6'} // bg-blue-500
            onClick={closePopup}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostPopup;