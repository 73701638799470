import React from 'react';

interface EmbedModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  embedIframe: string;
}

const EmbedModal: React.FC<EmbedModalProps> = ({ isOpen, toggleModal, embedIframe }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={toggleModal}>
      <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-end">
          <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: embedIframe }} />
      </div>
    </div>
  );
};

export default EmbedModal;