import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabase';

interface Wall {
  id: string;
  name: string;
  url: string;
  tags: string;
  organization_id: string | null;
}

interface UserRole {
  role: string;
  organization_id: string | null;
}

const WallList: React.FC = () => {
  const [walls, setWalls] = useState<Wall[]>([]);
  const [newWallName, setNewWallName] = useState('');
  const [newWallUrl, setNewWallUrl] = useState('');
  const [newWallTags, setNewWallTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState('');
  const [allTags, setAllTags] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [wallToDelete, setWallToDelete] = useState<Wall | null>(null);
  const [userRole, setUserRole] = useState<UserRole | null>(null);

  useEffect(() => {
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (userRole) {
      fetchWalls();

      const subscription = supabase
        .channel('walls_changes')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'walls' }, (payload) => {
          console.log('Change received:', payload);
          fetchWalls(); // Refetch all walls on any change
        })
        .subscribe((status) => {
          console.log('Subscription status:', status);
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [userRole]);

  const fetchUserRole = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('user_roles')
        .select('role, organization_id')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user role:', error);
        setError('Failed to fetch user role. Please try again.');
      } else if (data) {
        setUserRole(data);
      }
    }
  };

  const fetchWalls = async () => {
    setIsLoading(true);
    let query = supabase.from('walls').select('*');
    
    if (userRole && userRole.role !== 'super_admin') {
      if (userRole.organization_id) {
        query = query.eq('organization_id', userRole.organization_id);
      } else {
        query = query.is('organization_id', null);
      }
    }

    const { data, error } = await query;
    if (error) {
      console.error('Error fetching walls:', error);
      setError('Failed to fetch walls. Please try again.');
    } else {
      console.log('Fetched walls:', data);
      setWalls(data || []);
      
      // Extract all unique tags
      const tagSet = new Set<string>();
      data?.forEach(wall => {
        if (wall.tags) {
          wall.tags.split(',').forEach((tag: string) => tagSet.add(tag.trim()));
        }
      });
      setAllTags(Array.from(tagSet));
    }
    setIsLoading(false);
  };

  const createWall = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    if (!newWallName.trim() || !newWallUrl.trim()) {
      setError('Wall name and URL are required.');
      return;
    }
  
    if (!/^[a-zA-Z0-9_-]+$/.test(newWallUrl)) {
      setError('Wall URL can only contain letters, numbers, underscores, and hyphens.');
      return;
    }
  
    try {
      const { data: wall, error: wallError } = await supabase
        .from('walls')
        .insert({ 
          name: newWallName.trim(), 
          url: newWallUrl.trim(),
          tags: newWallTags.join(','),
          organization_id: userRole?.organization_id
        })
        .single();
  
      if (wallError) throw wallError;

      console.log('Wall created:', wall);
      setNewWallName('');
      setNewWallUrl('');
      setNewWallTags([]);
      setTagInput('');
      fetchWalls();
    } catch (error) {
      console.error('Error creating wall:', error);
      setError('Failed to create wall. Please try again.');
    }
  };

  const deleteWall = async (wall: Wall) => {
    try {
      const { error } = await supabase
        .from('walls')
        .delete()
        .eq('id', wall.id);

      if (error) throw error;

      console.log('Wall deleted successfully');
      setWallToDelete(null);
      fetchWalls();
    } catch (error) {
      console.error('Error deleting wall:', error);
      setError('Failed to delete wall. Please try again.');
    }
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setTagInput(input);
    
    if (input.endsWith(',')) {
      const newTag = input.slice(0, -1).trim();
      if (newTag && !newWallTags.includes(newTag)) {
        setNewWallTags([...newWallTags, newTag]);
      }
      setTagInput('');
    }
  };

  const removeTag = (tagToRemove: string) => {
    setNewWallTags(newWallTags.filter(tag => tag !== tagToRemove));
  };

  if (isLoading) return <div>Loading walls...</div>;

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">AI Media Wall</h2>

      {/* Create New Wall Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4">Create New Wall</h3>
        <form onSubmit={createWall} className="space-y-4">
          <div>
            <input
              type="text"
              value={newWallName}
              onChange={(e) => setNewWallName(e.target.value)}
              placeholder="Wall Name"
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <input
              type="text"
              value={newWallUrl}
              onChange={(e) => setNewWallUrl(e.target.value)}
              placeholder="Wall URL"
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <input
              type="text"
              value={tagInput}
              onChange={handleTagChange}
              placeholder="Add tags (comma-separated)"
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="flex flex-wrap gap-2">
            {newWallTags.map((tag, index) => (
              <span 
                key={index} 
                className="inline-flex items-center bg-blue-100 text-blue-800 px-2 py-1 rounded text-xs"
              >
                {tag}
                <button 
                  type="button" 
                  onClick={() => removeTag(tag)}
                  className="ml-1 text-blue-600 hover:text-blue-800"
                >
                  ✕
                </button>
              </span>
            ))}
          </div>
          <div>
            <p className="text-sm text-gray-600 mb-2">Existing tags:</p>
            <div className="flex flex-wrap gap-2">
              {allTags.map((tag, index) => (
                <span 
                  key={index} 
                  className="inline-block bg-gray-100 text-gray-800 px-2 py-1 rounded text-xs cursor-pointer"
                  onClick={() => {
                    if (!newWallTags.includes(tag)) {
                      setNewWallTags([...newWallTags, tag]);
                    }
                  }}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Create Wall
          </button>
        </form>
      </div>

      {/* Existing Walls Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4">Your Walls</h3>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {walls.length === 0 ? (
          <p>You haven't created any walls yet.</p>
        ) : (
          <ul className="space-y-2">
            {walls.map((wall) => (
              <li key={wall.id} className="flex items-center justify-between bg-gray-100 p-4 rounded">
                <div>
                  <h4 className="font-semibold">{wall.name}</h4>
                  <p className="text-sm text-gray-500">/{wall.url}</p>
                  <div className="mt-2">
                    {wall.tags && wall.tags.split(',').map((tag, index) => (
                      <span key={index} className="inline-block bg-blue-100 text-blue-800 px-2 py-1 rounded text-xs mr-2 mb-2">
                        {tag.trim()}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="space-x-2">
                  <Link 
                    to={`/admin/wall/${wall.url}`} 
                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Manage
                  </Link>
                  <Link 
                    to={`/wall/${wall.url}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    View
                  </Link>
                  <button 
                    onClick={() => setWallToDelete(wall)}
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {wallToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
            <p className="mb-4">Are you sure you want to delete the wall "{wallToDelete.name}"? This action cannot be undone.</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setWallToDelete(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => deleteWall(wallToDelete)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WallList;