import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation, Link, useNavigate } from 'react-router-dom';
import { supabase } from './supabase';
import { Session } from '@supabase/supabase-js';
import Auth from './components/Auth';
import WallList from './components/WallList';
import WallView from './components/WallView';
import PublicWallView from './components/PublicWallView';
import ResetPassword from './components/ResetPassword';
import GlobalErrorBoundary from './components/GlobalErrorBoundary';
import UserManagement from './components/UserManagement';
import OrganizationManagement from './components/OrganizationManagement';
import { logger } from './utils/logger';

interface UserRole {
  role: string;
  organization_id: string | null;
}

const Header: React.FC<{ userRole: UserRole | null; onLogout: () => void }> = ({ userRole, onLogout }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname === '/' || location.pathname.startsWith('/admin');

  if (!isAdminRoute) return null;

  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">AI Media Wall</h1>
        <nav>
          <ul className="flex space-x-4">
            <li><Link to="/" className="text-blue-500 hover:text-blue-700">Walls</Link></li>
            {userRole && userRole.role === 'super_admin' && (
              <>
                <li><Link to="/admin/users" className="text-blue-500 hover:text-blue-700">Users</Link></li>
                <li><Link to="/admin/organizations" className="text-blue-500 hover:text-blue-700">Organizations</Link></li>
              </>
            )}
            {userRole && (
              <li>
                <button onClick={onLogout} className="text-blue-500 hover:text-blue-700">
                  Logout
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

const AppContent: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isPublicWallRoute = !location.pathname.startsWith('/admin') && location.pathname !== '/';

  const fetchUserRole = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('user_roles')
        .select('role, organization_id')
        .eq('user_id', userId)
        .single();

      if (error) throw error;
      if (data) {
        setUserRole(data);
        logger.info('User role fetched successfully', { userId, role: data.role });
      }
    } catch (error) {
      logger.error('Error fetching user role', { userId, error });
      setError('Failed to fetch user role. Please try logging in again.');
    }
  };

  const checkAndRefreshSession = async () => {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) throw error;
      
      if (data.session) {
        setSession(data.session);
        await fetchUserRole(data.session.user.id);
        logger.info('Session refreshed successfully', { userId: data.session.user.id });
      } else {
        setSession(null);
        setUserRole(null);
        logger.info('No active session found');
      }
    } catch (error) {
      logger.error('Error checking session', { error });
      setError('Failed to verify your session. Please try logging in again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAndRefreshSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        fetchUserRole(session.user.id);
        logger.info('Auth state changed', { event: _event, userId: session.user.id });
      } else {
        setUserRole(null);
        logger.info('Auth state changed: User logged out');
      }
    });

    // Periodically check and refresh the session
    const intervalId = setInterval(checkAndRefreshSession, 5 * 60 * 1000); // Every 5 minutes

    return () => {
      subscription.unsubscribe();
      clearInterval(intervalId);
      logger.info('Cleanup: Unsubscribed from auth changes and cleared interval');
    };
  }, []);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setSession(null);
      setUserRole(null);
      navigate('/');
      logger.info('User logged out successfully');
    } catch (error) {
      logger.error('Error during logout', { error });
      setError('An error occurred during logout. Please try again.');
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={() => {
            setError(null);
            navigate('/');
          }}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Return to Login
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header userRole={userRole} onLogout={handleLogout} />
      <main className={isPublicWallRoute ? '' : 'max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'}>
        <Routes>
          <Route path="/" element={session ? <WallList /> : <Auth />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin/wall/:name" element={session ? <WallView /> : <Auth />} />
          <Route path="/wall/:name" element={<PublicWallView />} />
          {userRole && userRole.role === 'super_admin' && (
            <>
              <Route path="/admin/users" element={<UserManagement />} />
              <Route path="/admin/organizations" element={<OrganizationManagement />} />
            </>
          )}
          <Route path="*" element={<h1>404: Page Not Found</h1>} />
        </Routes>
      </main>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <GlobalErrorBoundary>
      <Router>
        <AppContent />
      </Router>
    </GlobalErrorBoundary>
  );
};

export default App;