import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { useNavigate } from 'react-router-dom';
import { AuthError } from '@supabase/supabase-js';
import { logger } from '../utils/logger';

const Auth: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  const [isResetMode, setIsResetMode] = useState(false);
  const [resetRequestTime, setResetRequestTime] = useState<number | null>(null);
  const [registrationEnabled, setRegistrationEnabled] = useState(false);
  const [organizations, setOrganizations] = useState<{ id: string; name: string }[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        logger.info('Existing session found', { userId: session.user.id });
        navigate('/');
      }
    };
    checkSession();

    const checkRegistrationStatus = async () => {
      const { data, error } = await supabase
        .from('app_settings')
        .select('registration_enabled')
        .single();
      if (data) {
        setRegistrationEnabled(data.registration_enabled);
        logger.info('Registration status checked', { registrationEnabled: data.registration_enabled });
      }
      if (error) {
        logger.error('Error checking registration status', { error });
      }
    };
    checkRegistrationStatus();

    const fetchOrganizations = async () => {
      const { data, error } = await supabase
        .from('organizations')
        .select('id, name');
      if (data) {
        setOrganizations(data);
        logger.info('Organizations fetched', { count: data.length });
      }
      if (error) {
        logger.error('Error fetching organizations', { error });
      }
    };
    fetchOrganizations();
  }, [navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      logger.info('Attempting login', { email });
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      logger.info('Login successful', { userId: data.user?.id });
      navigate('/');
    } catch (error) {
      if (error instanceof AuthError) {
        logger.error('Login error', { error: error.message });
        setError(error.message);
      } else {
        logger.error('Unexpected error during login', { error });
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      logger.info('Attempting registration', { email });
      const { data: { user }, error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      if (user) {
        logger.info('User registered successfully', { userId: user.id });
        const { error: roleError } = await supabase
          .from('user_roles')
          .insert({
            user_id: user.id,
            role: 'user',
            organization_id: selectedOrganization,
          });

        if (roleError) throw roleError;
        logger.info('User role assigned successfully', { userId: user.id, role: 'user', organizationId: selectedOrganization });
        alert('Registration successful. Please check your email to confirm your account.');
        setIsRegistering(false);
      }
    } catch (error) {
      if (error instanceof AuthError) {
        logger.error('Registration error', { error: error.message });
        setError(error.message);
      } else {
        logger.error('Unexpected error during registration', { error });
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (resetRequestTime && Date.now() - resetRequestTime < 60000) {
      const remainingTime = Math.ceil((60000 - (Date.now() - resetRequestTime)) / 1000);
      setError(`Please wait ${remainingTime} seconds before requesting another reset.`);
      logger.warn('Password reset request too frequent', { email, remainingTime });
      return;
    }

    setLoading(true);
    try {
      logger.info('Attempting password reset', { email });
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      logger.info('Password reset email sent successfully', { email });
      alert('Password reset email sent. Check your inbox.');
      setIsResetMode(false);
      setResetRequestTime(Date.now());
    } catch (error) {
      if (error instanceof AuthError) {
        logger.error('Password reset error', { error: error.message });
        setError(error.message);
      } else {
        logger.error('Unexpected error during password reset', { error });
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h1 className="text-2xl font-bold mb-4">
          {isResetMode ? 'Reset Password' : isRegistering ? 'Register' : 'Login'}
        </h1>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        {!isResetMode ? (
          <form onSubmit={isRegistering ? handleRegister : handleLogin}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            {isRegistering && (
              <div className="mb-4">
                <label htmlFor="organization" className="block text-sm font-medium text-gray-700">Organization</label>
                <select
                  id="organization"
                  value={selectedOrganization}
                  onChange={(e) => setSelectedOrganization(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                  <option value="">Select an organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>{org.name}</option>
                  ))}
                </select>
              </div>
            )}
            <button
              type="submit"
              disabled={loading || (isRegistering && !selectedOrganization)}
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
            >
              {loading ? 'Processing...' : isRegistering ? 'Register' : 'Login'}
            </button>
            {!isRegistering && (
              <p className="mt-4 text-center">
                <button
                  type="button"
                  onClick={() => setIsResetMode(true)}
                  className="text-blue-500 hover:text-blue-700 underline"
                >
                  Forgot Password?
                </button>
              </p>
            )}
            {registrationEnabled && (
              <p className="mt-4 text-center">
                <button
                  type="button"
                  onClick={() => {
                    setIsRegistering(!isRegistering);
                    setSelectedOrganization('');
                    setError(null);
                  }}
                  className="text-blue-500 hover:text-blue-700 underline"
                >
                  {isRegistering ? 'Back to Login' : 'Need an account? Register'}
                </button>
              </p>
            )}
          </form>
        ) : (
          <form onSubmit={handlePasswordReset}>
            <div className="mb-4">
              <label htmlFor="reset-email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                id="reset-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </button>
            <p className="mt-4 text-center">
              <button
                type="button"
                onClick={() => {
                  setIsResetMode(false);
                  setError(null);
                }}
                className="text-blue-500 hover:text-blue-700 underline"
              >
                Back to Login
              </button>
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;