import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabase';
import EditableField from './EditableField';

interface Post {
  id: string;
  wall_id: string;
  image_url: string;
  original_image_url: string;
  title?: string;
  message?: string;
  resolution: string;
  is_visible: boolean;
}

interface PostListProps {
  wallId: string;
}

const PostList: React.FC<PostListProps> = ({ wallId }) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [visibilityFilter, setVisibilityFilter] = useState<'all' | 'visible' | 'hidden'>('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPosts = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('wall_id', wallId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [wallId]);

  useEffect(() => {
    fetchPosts();

    const subscription = supabase
      .channel('public:posts')
      .on('postgres_changes', 
        { 
          event: '*', 
          schema: 'public', 
          table: 'posts',
          filter: `wall_id=eq.${wallId}`
        }, 
        handleRealtimeUpdate
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [fetchPosts, wallId]);

  const handleRealtimeUpdate = (payload: any) => {
    console.log('Received real-time update:', payload);
    if (payload.eventType === 'INSERT') {
      console.log('Inserting new post');
      setPosts(currentPosts => [payload.new, ...currentPosts]);
    } else if (payload.eventType === 'UPDATE') {
      console.log('Updating existing post');
      setPosts(currentPosts => 
        currentPosts.map(post => 
          post.id === payload.new.id ? { ...post, ...payload.new } : post
        )
      );
    } else if (payload.eventType === 'DELETE') {
      console.log('Deleting post');
      setPosts(currentPosts => 
        currentPosts.filter(post => post.id !== payload.old.id)
      );
    }
  };

  const handleEditPost = async (postId: string, updates: { title?: string; message?: string }) => {
    try {
      const { error } = await supabase
        .from('posts')
        .update(updates)
        .eq('id', postId);

      if (error) throw error;

      // The real-time subscription will handle the update in the UI
    } catch (error) {
      console.error('Error updating post:', error);
      setError('Failed to update post');
    }
  };

  const togglePostVisibility = async (postId: string, currentVisibility: boolean) => {
    try {
      const { error } = await supabase
        .from('posts')
        .update({ is_visible: !currentVisibility })
        .eq('id', postId);

      if (error) throw error;

      // The real-time subscription will handle the update in the UI
    } catch (error) {
      console.error('Error toggling post visibility:', error);
      setError('Failed to toggle post visibility');
    }
  };

  const deletePost = async (postId: string) => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    try {
      console.log('Deleting post:', postId);
      const { error } = await supabase
        .from('posts')
        .delete()
        .eq('id', postId);

      if (error) throw error;

      console.log('Post deleted successfully');
      // The real-time subscription will handle the removal from the UI
    } catch (error) {
      console.error('Error deleting post:', error);
      setError('Failed to delete post');
    }
  };

  const filteredPosts = posts.filter(post => {
    if (visibilityFilter === 'all') return true;
    return visibilityFilter === 'visible' ? post.is_visible : !post.is_visible;
  });

  if (isLoading) return <div>Loading posts...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Manage Posts</h3>
      <div className="mb-4">
        <label className="mr-2">Filter posts:</label>
        <select
          value={visibilityFilter}
          onChange={(e) => setVisibilityFilter(e.target.value as 'all' | 'visible' | 'hidden')}
          className="border rounded px-2 py-1"
        >
          <option value="all">All Posts</option>
          <option value="visible">Visible Posts</option>
          <option value="hidden">Hidden Posts</option>
        </select>
      </div>
      {filteredPosts.length === 0 ? (
        <p>No posts match the current filter.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredPosts.map((post) => (
            <div key={post.id} className={`border rounded shadow overflow-hidden ${!post.is_visible ? 'opacity-50' : ''}`}>
              <img 
                src={post.image_url} 
                alt={post.title || 'Post image'} 
                className="w-full h-auto"
                style={{ 
                  aspectRatio: post.resolution === '576x1024' ? '9/16' : post.resolution === '1024x1024' ? '1' : '16/9',
                  objectFit: 'cover'
                }}
              />
              <div className="p-4">
                <EditableField
                  value={post.title || ''}
                  onSave={(newTitle) => handleEditPost(post.id, { title: newTitle })}
                  fieldName="title"
                />
                <EditableField
                  value={post.message || ''}
                  onSave={(newMessage) => handleEditPost(post.id, { message: newMessage })}
                  fieldName="message"
                />
                <div className="flex justify-between mt-2">
                  <button 
                    onClick={() => togglePostVisibility(post.id, post.is_visible)} 
                    className={`px-3 py-1 ${post.is_visible ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-green-500 hover:bg-green-600'} text-white rounded mr-2`}
                  >
                    {post.is_visible ? 'Hide' : 'Show'}
                  </button>
                  <button 
                    onClick={() => deletePost(post.id)} 
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostList;