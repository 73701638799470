import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabase';
import { motion, AnimatePresence } from 'framer-motion';
import './PublicWallView.css';
import PostCard from './PostCard';
import PostPopup from './PostPopup';
import EmbedModal from './EmbedModal';
import { useWallData } from '../hooks/useWallData';
import { usePostsData } from '../hooks/usePostsData';
import { Post, PopupPost } from '../types';
import PauseButton from './PauseButton';

const PublicWallView: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const { wall, wallStyles, isLoading: isWallLoading, error: wallError } = useWallData(name);
  const { posts, isLoading: isPostsLoading, error: postsError, togglePostVisibility, realtimeSubscription } = usePostsData(wall);
  const [user, setUser] = useState<any | null>(null);
  const [popupPost, setPopupPost] = useState<PopupPost | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const heartbeatIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const connectionCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [lastPostCount, setLastPostCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const pauseTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showPauseButton, setShowPauseButton] = useState(false);

  const visiblePosts = useMemo(() => {
    return posts.filter(post => post.is_visible);
  }, [posts]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    fetchUser();

    // Set up heartbeat
    heartbeatIntervalRef.current = setInterval(() => {
      if (realtimeSubscription) {
        realtimeSubscription.send({
          type: 'broadcast',
          event: 'heartbeat',
          payload: {},
        });
      }
    }, 60000); // Send heartbeat every 60 seconds

    // Set up connection check
    connectionCheckIntervalRef.current = setInterval(() => {
      if (realtimeSubscription && realtimeSubscription.state !== 'SUBSCRIBED') {
        console.log('Realtime subscription not in SUBSCRIBED state. Reconnecting...');
        realtimeSubscription.unsubscribe();
        realtimeSubscription.subscribe();
      }
    }, 30000); // Check connection every 30 seconds

    return () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      if (connectionCheckIntervalRef.current) {
        clearInterval(connectionCheckIntervalRef.current);
      }
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [realtimeSubscription]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && realtimeSubscription) {
        // Check connection status and reconnect if necessary
        if (realtimeSubscription.state !== 'SUBSCRIBED') {
          console.log('Realtime subscription not in SUBSCRIBED state. Reconnecting...');
          realtimeSubscription.unsubscribe();
          realtimeSubscription.subscribe();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [realtimeSubscription]);

  useEffect(() => {
    console.log('Wall data in PublicWallView:', wall);
    console.log('Container ref:', containerRef.current);

    if (wall?.auto_scroll_enabled && containerRef.current) {
      console.log('Setting up auto-scroll. Speed:', wall.scroll_speed);

      const scrollContent = () => {
        if (containerRef.current && !isPaused) {
          containerRef.current.scrollTop += 1;
          if (containerRef.current.scrollHeight - containerRef.current.scrollTop <= containerRef.current.clientHeight) {
            containerRef.current.scrollTop = 0;
          }
        }
      };

      scrollIntervalRef.current = setInterval(scrollContent, 1000 / (wall.scroll_speed || 30));

      return () => {
        if (scrollIntervalRef.current) {
          clearInterval(scrollIntervalRef.current);
        }
      };
    } else {
      console.log('Auto-scroll not enabled or container not found');
      console.log('Wall:', wall);
      console.log('Container:', containerRef.current);
    }
  }, [wall, isPaused]);

  useEffect(() => {
    if (visiblePosts.length > lastPostCount) {
      console.log('New post detected. Scrolling to top and pausing for 7 seconds.');
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
      setLastPostCount(visiblePosts.length);
      setIsPaused(true);
      
      if (pauseTimeoutRef.current) {
        clearTimeout(pauseTimeoutRef.current);
      }
      
      pauseTimeoutRef.current = setTimeout(() => {
        console.log('Resuming auto-scroll after new post');
        setIsPaused(false);
      }, 7000);
    }
  }, [visiblePosts.length, lastPostCount]);

  const openPopup = (post: Post) => {
    setPopupPost({ ...post, isOpen: true });
  };

  const closePopup = () => {
    setPopupPost(null);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  if (isWallLoading || isPostsLoading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  if (wallError) return <div className="flex items-center justify-center min-h-screen text-red-500">{wallError}</div>;
  if (postsError) return <div className="flex items-center justify-center min-h-screen text-red-500">{postsError}</div>;
  if (!wall) return <div className="flex items-center justify-center min-h-screen">Wall not found</div>;

  return (
    <div 
      className="w-full min-h-screen relative" 
      style={{ backgroundColor: wallStyles?.page_background_color || '#f3f4f6' }}
      onMouseEnter={() => setShowPauseButton(true)}
      onMouseLeave={() => setShowPauseButton(false)}
    >
      {!wall.hide_name && (
        <header style={{ backgroundColor: wallStyles?.header_background_color || '#ffffff' }} className="shadow w-full">
          <div className="max-w-full mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="flex items-center space-x-6">
              {wall.show_logo && wall.logo_url && (
                <img src={wall.logo_url} alt="Wall Logo" className="h-10 w-auto" />
              )}
              <h1 className="text-2xl font-bold" style={{ 
                color: wallStyles?.wall_name_color || '#000000', 
                fontSize: wallStyles?.title_font_size || '24px',
                fontFamily: wallStyles?.title_font || 'Arial'
              }}>{wall.name}</h1>
            </div>
            {wall.show_plus_button && (
              <button
                onClick={toggleModal}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </button>
            )}
          </div>
        </header>
      )}
      <div 
        ref={containerRef}
        className={`max-w-full mx-auto px-4 sm:px-6 lg:px-8 ${wall.hide_name ? 'mt-0' : 'mt-4'}`}
        style={{ height: 'calc(100vh - 80px)', overflowY: 'auto' }}
      >
        {visiblePosts.length === 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex justify-center items-center h-64"
          >
            <p className="text-xl text-gray-500">No posts were found</p>
          </motion.div>
        ) : (
          <motion.div 
            layout 
            className="public-wall-grid gap-6 mt-4"
            style={{
              '--grid-cols-sm': wallStyles?.cols_sm || 2,
              '--grid-cols-md': wallStyles?.cols_md || 3,
              '--grid-cols-lg': wallStyles?.cols_lg || 4,
              '--grid-cols-xl': wallStyles?.cols_xl || 5,
              '--grid-cols-2xl': wallStyles?.cols_2xl || 6,
              '--grid-cols-3xl': wallStyles?.cols_3xl || 7,
              '--grid-cols-4xl': wallStyles?.cols_4xl || 8,
              '--grid-cols-5xl': wallStyles?.cols_5xl || 9,
            } as React.CSSProperties}
          >
            <AnimatePresence>
              {visiblePosts.map((post) => (
                <motion.div
                  key={post.id}
                  layout
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.5 }}
                >
                  <PostCard
                    post={post}
                    wallStyles={wallStyles}
                    user={user}
                    openPopup={openPopup}
                    togglePostVisibility={togglePostVisibility}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>

      {wall.auto_scroll_enabled && (
        <PauseButton 
          show={showPauseButton} 
          isPaused={isPaused} 
          togglePause={togglePause} 
        />
      )}

      {popupPost && (
        <PostPopup
          popupPost={popupPost}
          wallStyles={wallStyles}
          wall={wall}
          closePopup={closePopup}
        />
      )}
      
      <EmbedModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        embedIframe={wall.embed_iframe}
      />
    </div>
  );
};

export default PublicWallView;