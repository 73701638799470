import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { Wall } from '../types';

interface WallSettingsProps {
  wall: Wall;
  onUpdate: (updatedWall: Wall) => void;
  onClose: () => void;
}

const WallSettings: React.FC<WallSettingsProps> = ({ wall, onUpdate, onClose }) => {
  const [newWallName, setNewWallName] = useState(wall.name);
  const [newWallUrl, setNewWallUrl] = useState(wall.url);
  const [hideWallName, setHideWallName] = useState(wall.hide_name);
  const [showPlusButton, setShowPlusButton] = useState(wall.show_plus_button);
  const [embedIframe, setEmbedIframe] = useState(wall.embed_iframe || '');
  const [showLogo, setShowLogo] = useState(wall.show_logo);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(wall.auto_scroll_enabled);
  const [scrollSpeed, setScrollSpeed] = useState(wall.scroll_speed || 30);
  const [error, setError] = useState<string | null>(null);
  const [logoUrl, setLogoUrl] = useState(wall.logo_url);

  // New state for sharing settings
  const [enableSharing, setEnableSharing] = useState(wall.enable_sharing || false);
  const [sharePlatforms, setSharePlatforms] = useState(wall.share_platforms || {
    facebook: true,
    twitter: true,
    linkedin: true
  });
  const [shareMessageTemplate, setShareMessageTemplate] = useState(wall.share_message_template || 'Check out this post from {wall_name}!');
  const [shareElements, setShareElements] = useState(wall.share_elements || {
    title: true,
    message: true,
    image: true
  });

  useEffect(() => {
    setNewWallName(wall.name);
    setNewWallUrl(wall.url);
    setHideWallName(wall.hide_name);
    setShowPlusButton(wall.show_plus_button);
    setEmbedIframe(wall.embed_iframe || '');
    setShowLogo(wall.show_logo);
    setAutoScrollEnabled(wall.auto_scroll_enabled);
    setScrollSpeed(wall.scroll_speed || 30);
    setLogoUrl(wall.logo_url);
    setEnableSharing(wall.enable_sharing || false);
    setSharePlatforms(wall.share_platforms || { facebook: true, twitter: true, linkedin: true });
    setShareMessageTemplate(wall.share_message_template || 'Check out this post from {wall_name}!');
    setShareElements(wall.share_elements || { title: true, message: true, image: true });
  }, [wall]);

  const handleRenameWall = async () => {
    if (!newWallName.trim() || !newWallUrl.trim()) return;
    setError(null);

    if (!/^[a-zA-Z0-9_-]+$/.test(newWallUrl)) {
      setError('URL can only contain letters, numbers, underscores, and hyphens.');
      return;
    }

    try {
      const { data: existingWall, error: checkError } = await supabase
        .from('walls')
        .select('id')
        .eq('url', newWallUrl)
        .neq('id', wall.id)
        .maybeSingle();

      if (checkError) throw checkError;

      if (existingWall) {
        setError('This URL is already in use. Please choose a different one.');
        return;
      }

      const updatedWall: Partial<Wall> = {
        name: newWallName.trim(),
        url: newWallUrl.trim(),
        hide_name: hideWallName,
        show_plus_button: showPlusButton,
        embed_iframe: embedIframe,
        show_logo: showLogo,
        auto_scroll_enabled: autoScrollEnabled,
        scroll_speed: scrollSpeed,
        logo_url: logoUrl,
        enable_sharing: enableSharing,
        share_platforms: sharePlatforms,
        share_message_template: shareMessageTemplate,
        share_elements: shareElements
      };

      const { error } = await supabase
        .from('walls')
        .update(updatedWall)
        .eq('id', wall.id);

      if (error) throw error;

      onUpdate({ ...wall, ...updatedWall });
      onClose();
    } catch (error) {
      console.error('Error updating wall:', error);
      setError('Error updating wall settings. Please try again.');
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileName = `logo_${wall.id}_${Date.now()}.${file.name.split('.').pop()}`;
      const filePath = `${wall.id}/${fileName}`;
  
      try {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        await new Promise((resolve) => (img.onload = resolve));
  
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error('Could not get canvas context');
  
        let width = img.width;
        let height = img.height;
  
        if (width > 100) {
          height = (height * 100) / width;
          width = 100;
        }
  
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
  
        const resizedBlob = await new Promise<Blob>((resolve) =>
          canvas.toBlob((blob) => resolve(blob!), 'image/jpeg', 0.95)
        );
  
        const { error: uploadError } = await supabase.storage
          .from('other-images')
          .upload(filePath, resizedBlob, {
            contentType: 'image/jpeg',
          });
  
        if (uploadError) throw uploadError;
  
        const { data: urlData } = supabase.storage
          .from('other-images')
          .getPublicUrl(filePath);
  
        if (!urlData) throw new Error('Failed to get public URL for logo');
  
        setLogoUrl(urlData.publicUrl);
      } catch (error) {
        console.error('Error in logo upload process:', error);
        setError('Failed to upload logo. Please try again.');
      }
    }
  };

  const handleSharePlatformChange = (platform: keyof typeof sharePlatforms) => {
    setSharePlatforms(prev => ({ ...prev, [platform]: !prev[platform] }));
  };

  const handleShareElementChange = (element: keyof typeof shareElements) => {
    setShareElements(prev => ({ ...prev, [element]: !prev[element] }));
  };

  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Wall Settings</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          Close ×
        </button>
      </div>
      <div className="space-y-4">
        <div>
          <label htmlFor="wallName" className="block text-sm font-medium text-gray-700 mb-1">Wall Name</label>
          <input
            id="wallName"
            type="text"
            value={newWallName}
            onChange={(e) => setNewWallName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="wallUrl" className="block text-sm font-medium text-gray-700 mb-1">Wall URL</label>
          <input
            id="wallUrl"
            type="text"
            value={newWallUrl}
            onChange={(e) => setNewWallUrl(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="space-y-2">
          <div className="flex items-center">
            <input
              id="hideWallName"
              type="checkbox"
              checked={hideWallName}
              onChange={(e) => setHideWallName(e.target.checked)}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label htmlFor="hideWallName" className="ml-2 block text-sm text-gray-900">Hide wall name in public view</label>
          </div>
          <div className="flex items-center">
            <input
              id="showPlusButton"
              type="checkbox"
              checked={showPlusButton}
              onChange={(e) => setShowPlusButton(e.target.checked)}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label htmlFor="showPlusButton" className="ml-2 block text-sm text-gray-900">Show plus button in public view</label>
          </div>
        </div>
        {showPlusButton && (
          <div>
            <label htmlFor="embedIframe" className="block text-sm font-medium text-gray-700 mb-1">Embed iframe</label>
            <textarea
              id="embedIframe"
              value={embedIframe}
              onChange={(e) => setEmbedIframe(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              rows={4}
              placeholder="Paste your iframe embed code here"
            />
          </div>
        )}
        <div>
          <label htmlFor="logoUpload" className="block text-sm font-medium text-gray-700 mb-1">Upload Logo</label>
          <input
            type="file"
            id="logoUpload"
            accept="image/*"
            onChange={handleLogoUpload}
            className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
          />
        </div>
        {logoUrl && (
          <div className="flex items-center mt-2">
            <img src={logoUrl} alt="Wall Logo" className="max-h-20 mr-4" />
            <div className="flex items-center">
              <input
                id="showLogo"
                type="checkbox"
                checked={showLogo}
                onChange={(e) => setShowLogo(e.target.checked)}
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <label htmlFor="showLogo" className="ml-2 block text-sm text-gray-900">Show logo on public wall</label>
            </div>
          </div>
        )}
        <div className="flex items-center">
          <input
            id="autoScrollEnabled"
            type="checkbox"
            checked={autoScrollEnabled}
            onChange={(e) => setAutoScrollEnabled(e.target.checked)}
            className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
          />
          <label htmlFor="autoScrollEnabled" className="ml-2 block text-sm text-gray-900">Enable auto scroll</label>
        </div>
        {autoScrollEnabled && (
          <div>
            <label htmlFor="scrollSpeed" className="block text-sm font-medium text-gray-700 mb-1">Scroll Speed (px / second)</label>
            <input
              id="scrollSpeed"
              type="number"
              min="1"
              value={scrollSpeed}
              onChange={(e) => setScrollSpeed(parseInt(e.target.value))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        )}
        
        {/* New Post Sharing section */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Post Sharing Settings</h3>
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                id="enableSharing"
                type="checkbox"
                checked={enableSharing}
                onChange={(e) => setEnableSharing(e.target.checked)}
                className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <label htmlFor="enableSharing" className="ml-2 block text-sm text-gray-900">Enable post sharing</label>
            </div>
            {enableSharing && (
              <>
                <div>
                  <p className="text-sm font-medium text-gray-700 mb-1">Share on platforms:</p>
                  <div className="space-x-4">
                    {Object.keys(sharePlatforms).map((platform) => (
                      <label key={platform} className="inline-flex items-center">
                        <input
                          type="checkbox"
                          checked={sharePlatforms[platform as keyof typeof sharePlatforms]}
                          onChange={() => handleSharePlatformChange(platform as keyof typeof sharePlatforms)}
                          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        <span className="ml-2 text-sm text-gray-900 capitalize">{platform}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="shareMessageTemplate" className="block text-sm font-medium text-gray-700 mb-1">Share message template</label>
                  <input
                    id="shareMessageTemplate"
                    type="text"
                    value={shareMessageTemplate}
                    onChange={(e) => setShareMessageTemplate(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Use {wall_name} as a placeholder for the wall name"
                  />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700 mb-1">Include in share:</p>
                  <div className="space-x-4">
                    {Object.keys(shareElements).map((element) => (
                      <label key={element} className="inline-flex items-center">
                        <input
                          type="checkbox"
                          checked={shareElements[element as keyof typeof shareElements]}
                          onChange={() => handleShareElementChange(element as keyof typeof shareElements)}
                          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        <span className="ml-2 text-sm text-gray-900 capitalize">{element}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      <div className="mt-6 flex justify-end space-x-4">
        <button onClick={onClose} className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400">Cancel</button>
        <button onClick={handleRenameWall} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Save Changes</button>
      </div>
    </div>
  );
};

export default WallSettings;