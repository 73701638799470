import React from 'react';
import { Post, WallStyle } from '../types';

interface PostCardProps {
  post: Post;
  wallStyles: WallStyle | null;
  user: any | null;
  openPopup: (post: Post) => void;
  togglePostVisibility: (postId: string, currentVisibility: boolean) => void;
}

const PostCard: React.FC<PostCardProps> = ({ post, wallStyles, user, openPopup, togglePostVisibility }) => {
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const getPostAspectRatio = (resolution: string) => {
    const [width, height] = resolution.split('x').map(Number);
    return width && height ? width / height : 1;
  };

  const getWallAspectRatio = (aspectRatio: string) => {
    const [width, height] = aspectRatio.split(':').map(Number);
    return width && height ? width / height : 1;
  };

  const postAspectRatio = getPostAspectRatio(post.resolution);
  const wallAspectRatio = wallStyles?.image_aspect_ratio ? getWallAspectRatio(wallStyles.image_aspect_ratio) : 1;

  const containerStyle = {
    paddingBottom: `${(1 / wallAspectRatio) * 100}%`,
  };

  const imageStyle = {
    objectFit: postAspectRatio > wallAspectRatio ? 'contain' : 'cover',
  } as React.CSSProperties;

  return (
    <div
      className="rounded-lg shadow-md overflow-hidden cursor-pointer h-full"
      onClick={() => openPopup(post)}
      style={{ backgroundColor: wallStyles?.post_background_color || '#ffffff' }}
    >
      <div className="relative h-0" style={containerStyle}>
        <img 
          src={post.image_url} 
          alt={post.title || 'Post image'} 
          className="absolute top-0 left-0 w-full h-full"
          style={imageStyle}
        />
        {user && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              togglePostVisibility(post.id, post.is_visible);
            }}
            className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md"
            title="Hide/Show post"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7z" />
            </svg>
          </button>
        )}
      </div>
      <div className="p-4">
        {post.title && <h2 className="text-xl font-semibold mb-2" style={{ 
          color: wallStyles?.title_color || '#000000', 
          fontSize: wallStyles?.title_font_size || '24px',
          fontFamily: wallStyles?.title_font || 'Arial'
        }}>{truncateText(post.title, 30)}</h2>}
        {post.message && <p style={{ 
          color: wallStyles?.message_color || '#000000', 
          fontSize: wallStyles?.message_font_size || '16px',
          fontFamily: wallStyles?.message_font || 'Arial'
        }}>{truncateText(post.message, 64)}</p>}
      </div>
    </div>
  );
};

export default PostCard;