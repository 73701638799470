import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [changeLoading, setChangeLoading] = useState<{[key: string]: boolean}>({});
  const [newUser, setNewUser] = useState({ email: '', password: '', role: 'user', organization_id: '' });
  const [currentUserRole, setCurrentUserRole] = useState<string | null>(null);

  useEffect(() => {
    fetchUsers();
    fetchOrganizations();
    fetchCurrentUserRole();
  }, []);

  const fetchCurrentUserRole = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      const { data, error } = await supabase
        .from('user_roles')
        .select('role')
        .eq('user_id', session.user.id)
        .single();

      if (error) {
        console.error('Error fetching current user role:', error);
      } else if (data) {
        setCurrentUserRole(data.role);
      }
    }
  };

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('user_roles')
        .select('*')
        .order('email');

      if (error) {
        throw error;
      }

      setUsers(data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchOrganizations = async () => {
    const { data, error } = await supabase
      .from('organizations')
      .select('*');
    if (error) {
      console.error('Error fetching organizations:', error);
    } else {
      setOrganizations(data || []);
    }
  };

  const handleRoleChange = async (userId: string, newRole: string) => {
    if (!window.confirm(`Are you sure you want to change this user's role to ${newRole}?`)) {
      return;
    }
    setChangeLoading(prev => ({ ...prev, [userId]: true }));
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      const response = await fetch(`${API_URL}/api/user-management/users/${userId}/role`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ role: newRole }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update user role');
      }

      fetchUsers();
    } catch (error) {
      if (error instanceof Error) {
        alert('Error updating role: ' + error.message);
      } else {
        alert('An unknown error occurred while updating the role');
      }
    } finally {
      setChangeLoading(prev => ({ ...prev, [userId]: false }));
    }
  };

  const handleOrgChange = async (userId: string, newOrgId: string) => {
    if (!window.confirm(`Are you sure you want to change this user's organization?`)) {
      return;
    }
    setChangeLoading(prev => ({ ...prev, [userId]: true }));
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      const response = await fetch(`${API_URL}/api/user-management/users/${userId}/organization`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ organization_id: newOrgId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update user organization');
      }

      fetchUsers();
    } catch (error) {
      if (error instanceof Error) {
        alert('Error updating organization: ' + error.message);
      } else {
        alert('An unknown error occurred while updating the organization');
      }
    } finally {
      setChangeLoading(prev => ({ ...prev, [userId]: false }));
    }
  };

  const handleDeleteUser = async (userId: string, userEmail: string) => {
    if (!window.confirm(`Are you sure you want to delete the user ${userEmail}? This action cannot be undone.`)) {
      return;
    }
    setChangeLoading(prev => ({ ...prev, [userId]: true }));
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      const response = await fetch(`${API_URL}/api/user-management/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete user');
      }

      alert('User deleted successfully');
      fetchUsers();
    } catch (error) {
      if (error instanceof Error) {
        alert('Error deleting user: ' + error.message);
      } else {
        alert('An unknown error occurred while deleting the user');
      }
    } finally {
      setChangeLoading(prev => ({ ...prev, [userId]: false }));
    }
  };

  const handleNewUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      console.log('Fetching session...');
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error('Error fetching session:', sessionError);
        throw new Error('Failed to get session');
      }
      if (!session) {
        console.error('No active session');
        throw new Error('No active session');
      }

      console.log('Session fetched successfully');
      console.log('Making request to create user...');
      const response = await fetch(`${API_URL}/api/user-management/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify(newUser),
      });

      console.log('Response status:', response.status);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        throw new Error(errorData.error || 'Failed to create user');
      }

      const data = await response.json();
      console.log('User created successfully:', data);
      alert('User created successfully');
      setNewUser({ email: '', password: '', role: 'user', organization_id: '' });
      fetchUsers();
    } catch (error) {
      console.error('Error in handleCreateUser:', error);
      if (error instanceof Error) {
        alert('Error creating user: ' + error.message);
      } else {
        alert('An unknown error occurred while creating the user');
      }
    } finally {
      setLoading(false);
    }
  };

  if (currentUserRole !== 'super_admin' && currentUserRole !== 'org_admin') {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">User Management</h2>
      
      {currentUserRole === 'super_admin' && (
        <form onSubmit={handleCreateUser} className="mb-8 p-4 bg-white shadow rounded">
          <h3 className="text-xl font-semibold mb-4">Create New User</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleNewUserChange}
              placeholder="Email"
              required
              className="p-2 border rounded"
            />
            <input
              type="password"
              name="password"
              value={newUser.password}
              onChange={handleNewUserChange}
              placeholder="Password"
              required
              className="p-2 border rounded"
            />
            <select
              name="role"
              value={newUser.role}
              onChange={handleNewUserChange}
              required
              className="p-2 border rounded"
            >
              <option value="user">User</option>
              <option value="moderator">Moderator</option>
              <option value="manager">Manager</option>
              <option value="org_admin">Organization Admin</option>
              <option value="super_admin">Super Admin</option>
            </select>
            <select
              name="organization_id"
              value={newUser.organization_id}
              onChange={handleNewUserChange}
              required
              className="p-2 border rounded"
            >
              <option value="">Select Organization</option>
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>{org.name}</option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
          >
            {loading ? 'Creating...' : 'Create User'}
          </button>
        </form>
      )}

      <div className="overflow-x-auto">
        <table className="w-full border-collapse border">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Email</th>
              <th className="border p-2">Role</th>
              <th className="border p-2">Organization</th>
              <th className="border p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id}>
                <td className="border p-2">{user.email}</td>
                <td className="border p-2">{user.role}</td>
                <td className="border p-2">
                  {organizations.find(org => org.id === user.organization_id)?.name || 'No Organization'}
                </td>
                <td className="border p-2">
                  {currentUserRole === 'super_admin' && (
                    <select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user.user_id, e.target.value)}
                      disabled={changeLoading[user.user_id]}
                      className="mr-2 p-1 border rounded"
                    >
                      <option value="user">User</option>
                      <option value="moderator">Moderator</option>
                      <option value="manager">Manager</option>
                      <option value="org_admin">Organization Admin</option>
                      <option value="super_admin">Super Admin</option>
                    </select>
                  )}
                  <select
                    value={user.organization_id || ''}
                    onChange={(e) => handleOrgChange(user.user_id, e.target.value)}
                    disabled={changeLoading[user.user_id] || (currentUserRole === 'org_admin' && user.role === 'super_admin')}
                    className="mr-2 p-1 border rounded"
                  >
                    <option value="">No Organization</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>{org.name}</option>
                    ))}
                  </select>
                  {currentUserRole === 'super_admin' && (
                    <button
                      onClick={() => handleDeleteUser(user.user_id, user.email)}
                      disabled={changeLoading[user.user_id]}
                      className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-red-300"
                    >
                      Delete
                    </button>
                  )}
                  {changeLoading[user.user_id] && <span className="ml-2">Loading...</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagement;