import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabase';
import { ClipboardIcon } from '@heroicons/react/24/outline';

interface ApiKeyManagerProps {
  wallId: string;
}

const ApiKeyManager: React.FC<ApiKeyManagerProps> = ({ wallId }) => {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [apiKeyError, setApiKeyError] = useState<string | null>(null);

  const fetchApiKey = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('walls')
        .select('api_key')
        .eq('id', wallId)
        .single();

      if (error) throw error;
      setApiKey(data.api_key || null);
      setApiKeyError(null);
    } catch (error) {
      console.error('Error fetching API key:', error);
      setApiKeyError('Failed to fetch API key');
      setApiKey(null);
    }
  }, [wallId]);

  useEffect(() => {
    fetchApiKey();
  }, [fetchApiKey]);

  const regenerateApiKey = async () => {
    try {
      setApiKeyError(null);
      const newApiKey = crypto.randomUUID();
      const { data, error } = await supabase
        .from('walls')
        .update({ api_key: newApiKey })
        .eq('id', wallId)
        .select('api_key')
        .single();

      if (error) throw error;
      setApiKey(data.api_key);
      console.log('API key regenerated successfully');
    } catch (error) {
      console.error('Error regenerating API key:', error);
      setApiKeyError('Failed to regenerate API key');
    }
  };

  const copyApiKey = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey).then(() => {
        console.log('API key copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy API key: ', err);
      });
    }
  };

  const copyApiUrl = () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/walls/${wallId}/posts`;
    navigator.clipboard.writeText(apiUrl).then(() => {
      console.log('API URL copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy API URL: ', err);
    });
  };

  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <h3 className="text-xl font-semibold mb-4">API Management</h3>
      <div className="space-y-4">
        <div>
          <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700">API Key</label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name="apiKey"
              id="apiKey"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              placeholder="API Key"
              value={apiKey || ''}
              readOnly
            />
            <button
              type="button"
              onClick={copyApiKey}
              className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 text-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <button
            onClick={regenerateApiKey}
            className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Regenerate API Key
          </button>
        </div>
        <div>
          <label htmlFor="apiUrl" className="block text-sm font-medium text-gray-700">API URL</label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name="apiUrl"
              id="apiUrl"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              value={`${process.env.REACT_APP_API_URL}/api/walls/${wallId}/posts`}
              readOnly
            />
            <button
              type="button"
              onClick={copyApiUrl}
              className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 text-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      {apiKeyError && <p className="mt-2 text-sm text-red-600">{apiKeyError}</p>}
    </div>
  );
};

export default ApiKeyManager;