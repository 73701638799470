import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface PauseButtonProps {
  show: boolean;
  isPaused: boolean;
  togglePause: () => void;
}

const PauseButton: React.FC<PauseButtonProps> = ({ show, isPaused, togglePause }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="absolute top-0 left-1/2 transform -translate-x-1/2 z-50"
        >
          <button
            onClick={togglePause}
            className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 focus:outline-none"
          >
            {isPaused ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            )}
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PauseButton;