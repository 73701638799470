import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { supabase } from '../supabase';
import { User } from '@supabase/supabase-js';
import WallSettings from './WallSettings';
import PostForm from './PostForm';
import PostList from './PostList';
import ApiKeyManager from './ApiKeyManager';
import StyleManager from './StyleManager';
import { useWallData } from '../hooks/useWallData';
import { Wall } from '../types'; // Import the Wall type from types.ts

interface UserRole {
  role: string;
  organization_id: string;
}

const WallView: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  const { wall, wallStyles, isLoading, error } = useWallData(name, refetchTrigger);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/login');
      } else {
        setUser(user);
        fetchUserRole(user.id);
      }
    };
    checkAuth();
  }, [navigate]);

  const fetchUserRole = async (userId: string) => {
    const { data, error } = await supabase
      .from('user_roles')
      .select('role, organization_id')
      .eq('user_id', userId)
      .single();

    if (error) {
      console.error('Error fetching user role:', error);
    } else if (data) {
      setUserRole(data);
    }
  };

  useEffect(() => {
    console.log('WallView component - wall:', wall);
    console.log('WallView component - wallStyles:', wallStyles);
    console.log('WallView component - isLoading:', isLoading);
    console.log('WallView component - error:', error);
  }, [wall, wallStyles, isLoading, error]);

  const handleWallUpdate = useCallback((updatedWall: Wall) => {
    console.log('Wall updated:', updatedWall);
    setRefetchTrigger(prev => prev + 1);
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!wall) return <div>Wall not found</div>;

  if (userRole && userRole.role !== 'super_admin' && wall.organization_id !== userRole.organization_id) {
    return <div>You don't have permission to view this wall.</div>;
  }

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Admin View: {wall.name}</h2>
      
      <div className="mb-6">
        <p className="text-lg mb-2">
          Public URL: <Link to={`/wall/${wall.url}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">/wall/{wall.url}</Link>
        </p>
      </div>

      <div className="space-y-4">
        <div className="bg-white shadow rounded-lg p-4">
          <button
            onClick={() => toggleSection('wallSettings')}
            className="w-full text-left font-semibold text-lg py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded"
          >
            Wall Settings
          </button>
          {activeSection === 'wallSettings' && (
            <div className="mt-4">
              <WallSettings
                wall={wall}
                onUpdate={handleWallUpdate}
                onClose={() => setActiveSection(null)}
              />
            </div>
          )}
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <button
            onClick={() => toggleSection('styleManager')}
            className="w-full text-left font-semibold text-lg py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded"
          >
            Style Manager
          </button>
          {activeSection === 'styleManager' && (
            <div className="mt-4">
              <StyleManager
                wallId={wall.id}
                onClose={() => setActiveSection(null)}
              />
            </div>
          )}
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <button
            onClick={() => toggleSection('apiManager')}
            className="w-full text-left font-semibold text-lg py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded"
          >
            API Management
          </button>
          {activeSection === 'apiManager' && (
            <div className="mt-4">
              <ApiKeyManager wallId={wall.id} />
            </div>
          )}
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <button
            onClick={() => toggleSection('addPost')}
            className="w-full text-left font-semibold text-lg py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded"
          >
            Add New Post
          </button>
          {activeSection === 'addPost' && (
            <div className="mt-4">
              <PostForm wallId={wall.id} />
            </div>
          )}
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <button
            onClick={() => toggleSection('managePosts')}
            className="w-full text-left font-semibold text-lg py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded"
          >
            Manage Posts
          </button>
          {activeSection === 'managePosts' && (
            <div className="mt-4">
              <PostList wallId={wall.id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WallView;