import React, { useState } from 'react';
import { supabase } from '../supabase';
import Resizer from 'react-image-file-resizer';

interface PostFormProps {
  wallId: string;
}

const resolutions = [
  { value: '1024x1024', label: 'Square (1024x1024)' },
  { value: '1024x576', label: 'Widescreen (1024x576)' },
  { value: '576x1024', label: 'Vertical (576x1024)' },
];

const PostForm: React.FC<PostFormProps> = ({ wallId }) => {
  const [newPostImage, setNewPostImage] = useState<File | null>(null);
  const [newPostTitle, setNewPostTitle] = useState('');
  const [newPostMessage, setNewPostMessage] = useState('');
  const [newPostResolution, setNewPostResolution] = useState('1024x1024');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewPostImage(e.target.files[0]);
    }
  };

  const resizeImage = (file: File, resolution: string): Promise<Blob> => {
    return new Promise((resolve) => {
      const [width, height] = resolution.split('x').map(Number);
      Resizer.imageFileResizer(
        file,
        width,
        height,
        'JPEG',
        90,
        0,
        (uri) => {
          fetch(uri as string)
            .then(res => res.blob())
            .then(blob => resolve(blob));
        },
        'base64'
      );
    });
  };

  const createPost = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newPostImage) {
      setError('No image selected');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      const file = newPostImage;
      
      // Upload original image
      const originalFileName = `original_${Math.random()}.jpg`;
      const originalFilePath = `${wallId}/${originalFileName}`;
      
      const { error: originalUploadError } = await supabase.storage
        .from('post-images')
        .upload(originalFilePath, file);

      if (originalUploadError) throw originalUploadError;

      const { data: originalUrlData } = supabase.storage
        .from('post-images')
        .getPublicUrl(originalFilePath);

      if (!originalUrlData) throw new Error('Failed to get public URL for original image');

      const originalPublicUrl = originalUrlData.publicUrl;

      // Resize and upload the display version
      const resizedImage = await resizeImage(file, newPostResolution);
      const resizedFileName = `${Math.random()}.jpg`;
      const resizedFilePath = `${wallId}/${resizedFileName}`;

      const { error: resizedUploadError } = await supabase.storage
        .from('post-images')
        .upload(resizedFilePath, resizedImage);

      if (resizedUploadError) throw resizedUploadError;

      const { data: resizedUrlData } = supabase.storage
        .from('post-images')
        .getPublicUrl(resizedFilePath);

      if (!resizedUrlData) throw new Error('Failed to get public URL for resized image');

      const resizedPublicUrl = resizedUrlData.publicUrl;

      const { data: postData, error: postError } = await supabase
        .from('posts')
        .insert({
          wall_id: wallId,
          image_url: resizedPublicUrl,
          original_image_url: originalPublicUrl,
          title: newPostTitle,
          message: newPostMessage,
          resolution: newPostResolution,
          is_visible: true
        })
        .select()
        .single();

      if (postError) throw postError;

      console.log('Post created successfully:', postData);
      setNewPostImage(null);
      setNewPostTitle('');
      setNewPostMessage('');
      setNewPostResolution('1024x1024');
    } catch (error) {
      console.error('Error creating post:', error);
      setError(`Error creating post: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <form onSubmit={createPost} className="mb-8 p-4 bg-white shadow rounded">
      <h3 className="text-xl font-semibold mb-4">Add New Post</h3>
      <div className="mb-4">
        <label className="block mb-2">Image:</label>
        <input type="file" accept="image/*" onChange={handleImageUpload} required className="w-full" />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Resolution:</label>
        <select
          value={newPostResolution}
          onChange={(e) => setNewPostResolution(e.target.value)}
          className="w-full p-2 border rounded"
        >
          {resolutions.map((res) => (
            <option key={res.value} value={res.value}>
              {res.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Title (optional):</label>
        <input
          type="text"
          value={newPostTitle}
          onChange={(e) => setNewPostTitle(e.target.value)}
          placeholder="Title (optional)"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Message (optional):</label>
        <textarea
          value={newPostMessage}
          onChange={(e) => setNewPostMessage(e.target.value)}
          placeholder="Message (optional)"
          className="w-full p-2 border rounded"
        />
      </div>
      <button
        type="submit"
        disabled={isUploading}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
      >
        {isUploading ? 'Uploading...' : 'Add Post'}
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  );
};

export default PostForm;