// src/components/OrganizationManagement.tsx
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';

const OrganizationManagement: React.FC = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [newOrgName, setNewOrgName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    const { data, error } = await supabase
      .from('organizations')
      .select('*');
    if (error) {
      console.error('Error fetching organizations:', error);
    } else {
      setOrganizations(data || []);
    }
  };

  const handleCreateOrg = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await supabase
      .from('organizations')
      .insert([{ name: newOrgName }]);
    if (error) {
      alert('Error creating organization: ' + error.message);
    } else {
      setNewOrgName('');
      fetchOrganizations();
    }
    setLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Organization Management</h2>
      <form onSubmit={handleCreateOrg} className="mb-8">
        <input
          type="text"
          value={newOrgName}
          onChange={(e) => setNewOrgName(e.target.value)}
          placeholder="New Organization Name"
          className="mr-2 p-2 border rounded"
        />
        <button
          type="submit"
          disabled={loading}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
        >
          {loading ? 'Creating...' : 'Create Organization'}
        </button>
      </form>
      <h3 className="text-xl font-semibold mb-2">Existing Organizations</h3>
      <ul className="list-disc pl-5">
        {organizations.map((org) => (
          <li key={org.id}>{org.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default OrganizationManagement;